@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.pselink {
  text-decoration-line: underline;
  color: rgb(0, 0, 187);
  cursor: pointer;
}

.offerlink {
  text-decoration-line: underline;
  cursor: pointer;
}

.MuiAutocomplete-root fieldset:hover {
  border-color: #2196F3 !important;
}

html, body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Poppins';
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}


/* New UI 2024*/
/*.btn-primary {*/
/*  color: #fff;*/
/*  background-color: #665bff;*/
/*  border-color: #837de0;*/
/*}*/

/*.btn-primary:hover {*/
/*  color: #fff;*/
/*  background-color: #5047d8;*/
/*  border-color: #655ed5;*/
/*}*/

/*.btn-primary:focus, .btn-primary:active {*/
/*  color: #fff;*/
/*  background-color: #5047d8;*/
/*  border-color: #655ed5;*/
/*  box-shadow: unset;*/
/*}*/
